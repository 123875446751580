<template>
  <button
    :class="cssClass"
    :type="type"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
export type Type = 'button' | 'submit' | 'reset';
export type Size = 'md' | 'lg';

export type Props = {
  type?: Type;
  size?: Size;
}
const props = withDefaults(defineProps<Props>(), {
  type: 'button',
  size: 'md'
});

const cssClass = computed(() => {
  const cssClass = [];

  switch (props.size) {
    case 'lg':
      cssClass.push('px-6 py-4 text-3xl border-4');
      break;

    case 'md':
    default:
      cssClass.push('px-4 py-2 text-base border');
  }

  return cssClass.join(' ');
});
</script>
